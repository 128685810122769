import { TenantEnum } from '@jsmdg/tenancy';
import { type TenantConfig } from '../../shared/types/tenantConfig';
import { type TenantSharedData } from '../../shared/types/tenantSharedData';
import { getTenantCondition } from '../utils';
import { getCheckoutLinks } from './checkoutLinks';
import { getFooterFirstLineLinks } from './footerFirstLineLinks';
import { getFooterSecondLineLinks } from './footerSecondLineLinks';
import { getLinksBlock } from './LinksBlock';
import { getSocialMedia } from './socialMedia';
import { getTrustpilotSettings } from './trustpilotSettings';

const discounts: Record<string, string> = {
    [TenantEnum.JS_DE]: '5 €',
    [TenantEnum.JS_AT]: '10 €',
    [TenantEnum.MD_DE]: '5 €',
    [TenantEnum.MD_AT]: '5 €',
    [TenantEnum.MD_CH]: '5 CHF',
};

function getTenantConfig(tenant: TenantEnum, sharedData: TenantSharedData): TenantConfig {
    const { isDE, isMydays } = getTenantCondition(tenant);

    const socialMedia = getSocialMedia(sharedData);

    const footerFirstLineLinks = getFooterFirstLineLinks({
        isDE,
        tenant,
        isMydays,
        sharedData,
    });
    const footerSecondLineLinks = getFooterSecondLineLinks({ isMydays, tenant, sharedData });
    const checkoutLinks = getCheckoutLinks({ isMydays, tenant, sharedData });
    const linkBlock = getLinksBlock(tenant);

    const brandName = isMydays ? 'mydays' : 'Jochen Schweizer';

    return {
        brandName,
        socialMedia,
        linkBlock,
        trustpilotSettings: getTrustpilotSettings(isMydays),
        footerLinks: [footerFirstLineLinks, footerSecondLineLinks],
        checkoutLinks,
        newsletter: {
            discount: discounts[tenant],
        },
    };
}

export { getTenantConfig };
