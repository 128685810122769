import { GA4EventName, GA4FeatureCategory, trackAnalyticsEvent } from '@jsmdg/tracking';

function createEventData(
    eventName: GA4EventName,
    featureCategory: GA4FeatureCategory,
    clickElement: string,
    clickText?: string,
    clickUrl?: string,
): Record<string, string> {
    return {
        eventName,
        feature_category: featureCategory,
        click_element: clickElement,
        ...(clickText && { click_text: clickText }),
        ...(clickUrl && { click_url: clickUrl }),
    };
}

export function trackTextlinkClick(label: string, click_text: string, click_url: string): void {
    const eventData = createEventData(
        GA4EventName.ClickTextLink,
        GA4FeatureCategory.FooterNavigation,
        label,
        click_text,
        click_url,
    );
    trackAnalyticsEvent({
        category: 'Footer',
        action: 'Click',
        label,
        eventData,
    });
}

export function trackSocialNetworkIconClick(label: string): void {
    const eventData = createEventData(
        GA4EventName.ClickButton,
        GA4FeatureCategory.FooterNavigation,
        label,
    );
    trackAnalyticsEvent({ eventData });
}

export function trackTrustpilot(click_url: string): void {
    const eventData = createEventData(
        GA4EventName.ClickTextLink,
        GA4FeatureCategory.FooterNavigation,
        'Trustpilot Widget',
        undefined,
        click_url,
    );
    trackAnalyticsEvent({ eventData });
}

export function trackNewsLetterSubscription(click_url: string): void {
    const eventData = createEventData(
        GA4EventName.ClickButton,
        GA4FeatureCategory.FooterNavigation,
        'Newsletter Subscription',
        'Abonnieren',
        click_url,
    );
    trackAnalyticsEvent({ eventData });
}
