(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@sentry/browser"), require("react"), require("react-dom"), require("react-intl"), require("react/jsx-runtime"));
	else if(typeof define === 'function' && define.amd)
		define(["@sentry/browser", "react", "react-dom", "react-intl", "react/jsx-runtime"], factory);
	else if(typeof exports === 'object')
		exports["Evelin"] = factory(require("@sentry/browser"), require("react"), require("react-dom"), require("react-intl"), require("react/jsx-runtime"));
	else
		root["Evelin"] = root["Evelin"] || {}, root["Evelin"]["fragments"] = root["Evelin"]["fragments"] || {}, root["Evelin"]["fragments"]["footer-fragment"] = factory(root["Sentry"], root["Evelin"]["fragments"]["react-base-fragment"]["React"], root["Evelin"]["fragments"]["react-base-fragment"]["ReactDom"], root["Evelin"]["fragments"]["react-base-fragment"]["ReactIntl"], root["Evelin"]["fragments"]["react-base-fragment"]["jsx"]);
})((typeof self != 'undefined' ? self : this), (__WEBPACK_EXTERNAL_MODULE__4691__, __WEBPACK_EXTERNAL_MODULE__1746__, __WEBPACK_EXTERNAL_MODULE__8575__, __WEBPACK_EXTERNAL_MODULE__7909__, __WEBPACK_EXTERNAL_MODULE__969__) => {
return 