import { type TenantEnum } from '@jsmdg/tenancy';
import { FooterLinkMessageId } from '../../shared/enums/footerLinkMessageIds';
import { FooterLinkTrackingId } from '../../shared/enums/footerLinkTrackingIds';
import { type FooterLink } from '../../shared/types/tenantConfig';
import { type TenantSharedData } from '../../shared/types/tenantSharedData';
import { legalNoticeUrls, termsOfUseUrls } from './commonUrls';

type Args = {
    tenant: TenantEnum;
    isMydays: boolean;
    sharedData: TenantSharedData;
};

export function getFooterSecondLineLinks({ isMydays, tenant, sharedData }: Args): FooterLink[] {
    if (isMydays)
        return [
            {
                url: 'https://unternehmen.mydays.de/pressebereich/',
                messageId: FooterLinkMessageId.Press,
                trackingId: FooterLinkTrackingId.Press,
            },
            {
                url: sharedData.urls.termAndConditions,
                messageId: FooterLinkMessageId.TermsAndConditions,
                trackingId: FooterLinkTrackingId.TermsAndConditions,
            },
            {
                url: '#uc-corner-modal-show',
                messageId: FooterLinkMessageId.CookieSettings,
                trackingId: FooterLinkTrackingId.CookieSettings,
            },
            {
                url: sharedData.urls.privacyPolicy,
                messageId: FooterLinkMessageId.Privacy,
                trackingId: FooterLinkTrackingId.Privacy,
            },
            {
                url: '/online-marktplatz/l/rt9af',
                messageId: FooterLinkMessageId.OnlineMarketplace,
                trackingId: FooterLinkTrackingId.OnlineMarketplace,
            },

            {
                url: '/Compliance/l/2ught',
                messageId: FooterLinkMessageId.Compliance,
                trackingId: FooterLinkTrackingId.Compliance,
            },
            {
                url: legalNoticeUrls[tenant],
                messageId: FooterLinkMessageId.LegalNotice,
                trackingId: FooterLinkTrackingId.LegalNotice,
            },
        ];

    return [
        {
            url: 'https://presse.jochen-schweizer.de/',
            messageId: FooterLinkMessageId.Press,
            trackingId: FooterLinkTrackingId.Press,
        },
        {
            url: sharedData.urls.termAndConditions,
            messageId: FooterLinkMessageId.TermsAndConditions,
            trackingId: FooterLinkTrackingId.TermsAndConditions,
        },
        {
            url: '#uc-corner-modal-show',
            messageId: FooterLinkMessageId.CookieSettings,
            trackingId: FooterLinkTrackingId.CookieSettings,
        },
        {
            url: sharedData.urls.privacyPolicy,
            messageId: FooterLinkMessageId.Privacy,
            trackingId: FooterLinkTrackingId.Privacy,
        },
        {
            url: termsOfUseUrls[tenant],
            messageId: FooterLinkMessageId.TermsOfUse,
            trackingId: FooterLinkTrackingId.TermsOfUse,
        },
        {
            url: 'Online-Marktplatz/JS_Online-Marktplatz/l/y6wix',
            messageId: FooterLinkMessageId.OnlineMarketplace,
            trackingId: FooterLinkTrackingId.OnlineMarketplace,
        },
        {
            url: '/compliance/l/aqzwr',
            messageId: FooterLinkMessageId.Compliance,
            trackingId: FooterLinkTrackingId.Compliance,
        },
        {
            url: legalNoticeUrls[tenant],
            messageId: FooterLinkMessageId.LegalNotice,
            trackingId: FooterLinkTrackingId.LegalNotice,
        },
    ];
}
