import { TenantEnum } from '@jsmdg/tenancy';

export const legalNoticeUrls = {
    [TenantEnum.JS_DE]: '/js/JS_IMPRESSUM/l/6ga7c',
    [TenantEnum.JS_AT]: '/js/JS_IMPRESSUM/l/6ga7c',
    [TenantEnum.MD_DE]: '/impressum/l/ydw7q',
    [TenantEnum.MD_AT]: '/impressum-at/l/owzgh',
    [TenantEnum.MD_CH]: '/impressum-ch/l/1jmnt',
} as Record<TenantEnum, string>;

export const termsOfUseUrls = {
    [TenantEnum.JS_DE]: '/Nutzungsbedingungen/JS_Nutzungsbedingungen/l/6gy7t',
    [TenantEnum.JS_AT]: '/Nutzungsbedingungen/JS_Nutzungsbedingungen/l/8uymh',
} as Record<TenantEnum, string>;

export const shippingUrls = {
    [TenantEnum.JS_DE]: '/versandinformation/versand-lieferung/l/ebv23',
    [TenantEnum.JS_AT]: '/versandinformation/versand-lieferung/l/i8nsr',
    [TenantEnum.MD_DE]: '/versand-lieferung/l/8fjrm',
    [TenantEnum.MD_AT]: '/versand-lieferung-at/l/sjwfd',
    [TenantEnum.MD_CH]: '/versand-lieferung-ch/l/ur4aq',
} as Record<TenantEnum, string>;

export const paymentUrls = {
    [TenantEnum.JS_DE]: '/bezahlung/zahlungsarten/l/6wy78',
    [TenantEnum.JS_AT]: '/bezahlung/zahlungsarten/l/6wy78',
    [TenantEnum.MD_DE]: '/zahlungsarten/l/tgpj4',
    [TenantEnum.MD_AT]: '/zahlungsarten-at/l/2hdj8',
    [TenantEnum.MD_CH]: '/zahlungsarten-ch/l/6hai8',
} as Record<TenantEnum, string>;

export const magazinUrls = {
    [TenantEnum.JS_DE]: 'https://magazin.jochen-schweizer.de/',
    [TenantEnum.JS_AT]: 'https://magazin.jochen-schweizer.de/',
    [TenantEnum.MD_DE]: 'https://magazin.mydays.de/',
    [TenantEnum.MD_AT]: 'https://magazin.mydays.at/',
    [TenantEnum.MD_CH]: 'https://magazin.mydays.ch/',
} as Record<TenantEnum, string>;
